.mapSectionFluid{
    background-color: #84449a;
}
.mapSectionWrapper{
    /* background-color: #84449a;/ */
    background-color: #1a1110;

}
.mapSectionContent{
    padding: 0rem 0rem;
}