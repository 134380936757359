/* Extra small devices (phones, 600px and down) */
@media (max-width: 480px) {

.orderingHeroWrapper{
    background-image: url("../../../../assets/images/bg.png");
    height: auto;
    background-position: left;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: repeat;
    background-size: contain;
    position: relative;
}
.orderingHeroContainer{
    position: relative;
    padding: 1rem 0rem;
}
.orderingHeroRow{
    flex-direction: column-reverse;
}
.orderingHeroRight{
    position: relative;
}
.orderingHeroLeft{
    position: relative;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 1rem;
}
.startScreen{
    background-image: url("../../../../assets/images/both.png");
     height: 300px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.orderAdSection{
    display: flex;
    gap: 3px;
    border-radius: 8px;
    background-color: #Faf9f6;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    align-items: flex-start;
}
.sectionIconImage{
    background-color: #Faf9f6;
    border-radius: 8px;
    padding: 10px;
}
.sectionIcon{
    background-image: url("../../../../assets/icons/playstore.png");
     height: 40px;
     width: 40px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.sectionText{
    align-self: center;
}
.sectionText h5{
    font-family: 'Lato', sans-serif;
    font-size: .8rem;
}
.sectionText h5:first-child{
    font-family: 'Lato', sans-serif;
    color: rgb(88, 88, 88);
    font-weight: bold;
    font-size: 1rem;
}
.orderSectionHead h2{
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.orderSectionButton{
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    border: none !important;
    background-color: #84449a !important;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 481px) and (max-width: 767px) {

.orderingHeroWrapper{
    background-image: url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/bg_yo3wpn");
    height: auto;
    background-position: left;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: repeat;
    background-size: contain;
    position: relative;
}
.orderingHeroContainer{
    position: relative;
    padding: 1rem 0rem;
}
.orderingHeroRight{
    position: relative;
}
.orderingHeroLeft{
    position: relative;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 1rem;
}
.startScreen{
    background-image: url("../../../../assets/images/both.png");
     height: 500px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.orderAdSection{
    display: flex;
    gap: 3px;
    border-radius: 8px;
    background-color: #Faf9f6;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    align-items: flex-start;
}
.sectionIconImage{
    background-color: #Faf9f6;
    border-radius: 8px;
    padding: 10px;
}
.sectionIcon{
    background-image: url("../../../../assets/icons/playstore.png");
     height: 40px;
     width: 40px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.sectionText{
    align-self: center;
}
.sectionText h5{
    font-family: 'Lato', sans-serif;
    font-size: .8rem;
}
.sectionText h5:first-child{
    font-family: 'Lato', sans-serif;
    color: rgb(88, 88, 88);
    font-weight: bold;
    font-size: 1rem;
}
.orderSectionHead h2{
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.orderSectionButton{
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    border: none !important;
    background-color: #84449a !important;
}
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {

.orderingHeroWrapper{
    background-image: url("../../../../assets/images/bg.png");
    height: auto;
    background-position: left;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: repeat;
    background-size: contain;
    position: relative;
}
.orderingHeroContainer{
    position: relative;
    padding: 1rem 0rem;
}
.orderingHeroRight{
    position: relative;
}
.orderingHeroLeft{
    position: relative;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 1rem;
}
.startScreen{
    background-image: url("../../../../assets/images/both.png");
     height: 500px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.orderAdSection{
    display: flex;
    gap: 3px;
    border-radius: 8px;
    background-color: #Faf9f6;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    align-items: flex-start;
}
.sectionIconImage{
    background-color: #Faf9f6;
    border-radius: 8px;
    padding: 10px;
}
.sectionIcon{
    background-image: url("../../../../assets/icons/playstore.png");
     height: 40px;
     width: 40px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.sectionText{
    align-self: center;
}
.sectionText h5{
    font-family: 'Lato', sans-serif;
    font-size: .8rem;
}
.sectionText h5:first-child{
    font-family: 'Lato', sans-serif;
    color: rgb(88, 88, 88);
    font-weight: bold;
    font-size: 1rem;
}
.orderSectionHead h2{
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.orderSectionButton{
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    border: none !important;
    background-color: #84449a !important;
}
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {

.orderingHeroWrapper{
    background-image: url("../../../../assets/images/bg.png");
    height: auto;
    background-position: left;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: repeat;
    background-size: contain;
    position: relative;
}
.orderingHeroContainer{
    position: relative;
    padding: 1rem 0rem;
}
.orderingHeroRight{
    position: relative;
}
.orderingHeroLeft{
    position: relative;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 1rem;
}
.startScreen{
    background-image: url("../../../../assets/images/both.png");
     height: 500px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.orderAdSection{
    display: flex;
    gap: 3px;
    border-radius: 8px;
    background-color: #Faf9f6;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    align-items: flex-start;
}
.sectionIconImage{
    background-color: #Faf9f6;
    border-radius: 8px;
    padding: 10px;
}
.sectionIcon{
    background-image: url("../../../../assets/icons/playstore.png");
     height: 40px;
     width: 40px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.sectionText{
    align-self: center;
}
.sectionText h5{
    font-family: 'Lato', sans-serif;
    font-size: .8rem;
}
.sectionText h5:first-child{
    font-family: 'Lato', sans-serif;
    color: rgb(88, 88, 88);
    font-weight: bold;
    font-size: 1rem;
}
.orderSectionHead h2{
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.orderSectionButton{
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    border: none !important;
    background-color: #84449a !important;
}
}

/* Media Query for Large screens */
@media (min-width: 1281px) {

.orderingHeroWrapper{
    background-image: url("../../../../assets/images/bg.png");
    height: auto;
    background-position: left;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: repeat;
    background-size: contain;
    position: relative;
}
.orderingHeroContainer{
    position: relative;
    padding: 1rem 0rem;
}
.orderingHeroRight{
    position: relative;
}
.orderingHeroLeft{
    position: relative;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 1rem;
}
.startScreen{
    background-image: url("../../../../assets//images/both.png");
     height: 500px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.orderAdSection{
    display: flex;
    gap: 3px;
    border-radius: 8px;
    background-color: #Faf9f6;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    align-items: flex-start;
}
.sectionIconImage{
    background-color: #Faf9f6;
    border-radius: 8px;
    padding: 10px;
}
.sectionIcon{
    background-image: url("../../../../assets/icons/playstore.png");
     height: 40px;
     width: 40px;
    background-position: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.sectionText{
    align-self: center;
}
.sectionText h5{
    font-family: 'Lato', sans-serif;
    font-size: .8rem;
}
.sectionText h5:first-child{
    font-family: 'Lato', sans-serif;
    color: rgb(88, 88, 88);
    font-weight: bold;
    font-size: 1rem;
}
.orderSectionHead h2{
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.orderSectionButton{
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    border: none !important;
    background-color: #84449a !important;
}
}
