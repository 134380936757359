.valuesSliderFluid{
    background-color: #84449a;
}
.valuesSliderWrapper{
    padding: 3rem 0rem;

}
.valueHead{
    padding-top: -1rem;
}

.valueHead h5{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    padding: 16px 0px 0px 0px;
    color: #fff;
}
.valueHead p{
    font-family: 'Oregano', cursive; 
    font-size: 1em;
    color: #fff;
    margin-top: -4px;
}
.valuesSliderContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 6px;
}
.sliderValue1{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top: 3px solid #f26721;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.sliderValue1 h5{
    font-size: 1.3rem;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-weight: bold;

}
.sliderValue1 p{
    padding: 4px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: .8rem;

}
.sliderValue2{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top: 3px solid #49a078;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.sliderValue2 h5{
    font-size: 1.3rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.sliderValue2 p{
    padding: 4px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: .8rem;

}
.sliderValue3{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top: 3px solid #800000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.sliderValue3 h5{
    font-size: 1.3rem;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-weight: bold;

}
.sliderValue3 p{
    padding: 4px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: .8rem;

}
.sliderValue4{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top: 3px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.sliderValue4 h5{
    font-size: 1.3rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.sliderValue4 p{
    padding: 4px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: .8rem;

}
.sliderValue5{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top: 3px solid yellow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.sliderValue5 h5{
    font-size: 1.3rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}
.sliderValue5 p{
    padding: 4px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: .8rem;

}