/* css for the second section of the page that has the menu items */
/* Extra small devices (phones, 600px and down) */
@media (max-width: 480px) {
    .dishMenuWrapper{
        background-image: url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/paper_yc6pl3");
        border-bottom-left-radius: -1.3em;
        border-bottom-right-radius: -1.3em;
        padding-bottom: 2em;
    }
    .dishMenuContent{
        position: relative;
    
    }
    .dishMenuContentHead{
        padding-top: 2em;
        display: block;
        top: -2em;
        left: 45%;
        margin: auto;
    
    }
    .dishMenuContentHead h5{
        font-family: 'Roboto', sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        color: #84449a;
    
    }
    .dishMenuContentHead p{
        font-family: 'Oregano', cursive; 
        font-size: 1em;
        color: #84449a;
        margin-top: -4px;
    }
    .dishMenuTabWrapper{
        width: 100%;
    }
    .menuContentLeft{
        background-color: none;
    }
    .menuTabLink{
        background-color: #FAF9F6;
        text-decoration: none !important;
        color: rgba(0, 0, 0, 0.55) !important;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
    .menuTabLink.active{
        background-color: #84449a !important;
        color: #FFF !important;
    }
    .menuContentRight{
        box-shadow: rgba(132, 68, 154, .7) 0px 5px 15px;
        border-radius: 8px;
    }
}
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 481px) and (max-width: 767px) {
    .dishMenuWrapper{
        background-image: url("../../../../assets/images/paper.png");
        border-bottom-left-radius: -1.3em;
        border-bottom-right-radius: -1.3em;
        padding-bottom: 2em;
    }
    .dishMenuContent{
        position: relative;
    
    }
    .dishMenuContentHead{
        padding-top: 2em;
        display: block;
        top: -2em;
        left: 45%;
        margin: auto;
    
    }
    .dishMenuContentHead h5{
        font-family: 'Roboto', sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        color: #84449a;
    
    }
    .dishMenuContentHead p{
        font-family: 'Oregano', cursive; 
        font-size: 1em;
        color: #84449a;
        margin-top: -4px;
    }
    .dishMenuTabWrapper{
        width: 100%;
    }
    .menuContentLeft{
        background-color: none;
        width: 100% !important;
    }
    .menuTabLink{
        background-color: #FAF9F6;
        text-decoration: none !important;
        color: rgba(0, 0, 0, 0.55) !important;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
    .menuTabLink.active{
        background-color: #84449a !important;
        color: #FFF !important;
    }
    .menuContentRight{
        box-shadow: rgba(132, 68, 154, .7) 0px 5px 15px;
        border-radius: 8px;
        width: 100% !important;
    }
   
  }
  
  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px) {
    .dishMenuWrapper{
        background-image: url("../../../../assets/images/paper.png");
        border-bottom-left-radius: -1.3em;
        border-bottom-right-radius: -1.3em;
        padding-bottom: 2em;
    }
    .dishMenuContent{
        position: relative;
    
    }
    .dishMenuContentHead{
        padding-top: 2em;
        display: block;
        top: -2em;
        left: 45%;
        margin: auto;
    
    }
    .dishMenuContentHead h5{
        font-family: 'Roboto', sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        color: #84449a;
    
    }
    .dishMenuContentHead p{
        font-family: 'Oregano', cursive; 
        font-size: 1em;
        color: #84449a;
        margin-top: -4px;
    }
    .dishMenuTabWrapper{
        width: 100%;
    }
    .menuContentLeft{
        background-color: none;
    }
    .menuTabLink{
        background-color: #FAF9F6;
        text-decoration: none !important;
        color: rgba(0, 0, 0, 0.55) !important;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
    .menuTabLink.active{
        background-color: #84449a !important;
        color: #FFF !important;
    }
    .menuContentRight{
        box-shadow: rgba(132, 68, 154, .7) 0px 5px 15px;
        border-radius: 8px;
    }
   
  }
  
  /* Media Query for Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px) {
    .dishMenuWrapper{
        background-image: url("../../../../assets/images/paper.png");
        border-bottom-left-radius: -1.3em;
        border-bottom-right-radius: -1.3em;
        padding-bottom: 2em;
    }
    .dishMenuContent{
        position: relative;
    
    }
    .dishMenuContentHead{
        padding-top: 2em;
        display: block;
        top: -2em;
        left: 45%;
        margin: auto;
    
    }
    .dishMenuContentHead h5{
        font-family: 'Roboto', sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        color: #84449a;
    
    }
    .dishMenuContentHead p{
        font-family: 'Oregano', cursive; 
        font-size: 1em;
        color: #84449a;
        margin-top: -4px;
    }
    .dishMenuTabWrapper{
        width: 100%;
    }
    .menuContentLeft{
        background-color: none;
    }
    .menuTabLink{
        background-color: #FAF9F6;
        text-decoration: none !important;
        color: rgba(0, 0, 0, 0.55) !important;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
    .menuTabLink.active{
        background-color: #84449a !important;
        color: #FFF !important;
    }
    .menuContentRight{
        box-shadow: rgba(132, 68, 154, .7) 0px 5px 15px;
        border-radius: 8px;
    }
   
  }
  
  /* Media Query for Large screens */
  @media (min-width: 1281px) {
    .dishMenuWrapper{
        background-image: url("../../../../assets/images/paper.png");
        border-bottom-left-radius: -1.3em;
        border-bottom-right-radius: -1.3em;
        padding-bottom: 2em;
    }
    .dishMenuContent{
        position: relative;
    
    }
    .dishMenuContentHead{
        padding-top: 2em;
        display: block;
        top: -2em;
        left: 45%;
        margin: auto;
    
    }
    .dishMenuContentHead h5{
        font-family: 'Roboto', sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        color: #84449a;
    
    }
    .dishMenuContentHead p{
        font-family: 'Oregano', cursive; 
        font-size: 1em;
        color: #84449a;
        margin-top: -4px;
    }
    .dishMenuTabWrapper{
        width: 100%;
    }
    .menuContentLeft{
        background-color: none;
    }
    .menuTabLink{
        background-color: #FAF9F6;
        text-decoration: none !important;
        color: rgba(0, 0, 0, 0.55) !important;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
    .menuTabLink.active{
        background-color: #84449a !important;
        color: #FFF !important;
    }
    .menuContentRight{
        box-shadow: rgba(132, 68, 154, .7) 0px 5px 15px;
        border-radius: 8px;
    }
    
  }
  
