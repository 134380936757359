/* Extra small devices (phones, 600px and down) */
@media (max-width: 480px) {

.eventsSpecialFluid{
    background-color: #fff;
    height: fit-content;

}
.eventsSpecialWrapper{
    background-color: #fff;
    padding: 3rem 0rem;
    height: fit-content;

}
.eventsSpecialHeader h5{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    padding: 16px 0px 0px 0px;
    color: #84449a;
}
.eventsSpecialHeader p{
    font-family: 'Oregano', cursive; 
    font-size: 1em;
    color: #84449a;
    margin-top: -4px;
}
.specialEvents{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:  180px 180px 180px;
    column-gap: 1rem;
    height: auto;
    row-gap: 2rem;
}
.eventGrid1{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/1_ndudyn");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.eventGrid2{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/2_qjasnl");
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    
}
.eventGrid3{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/3_qgpid8");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid4{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/4_uwfpbx");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid5{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/55_v48gjk");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid6{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/6_zsylk2");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid1 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid2 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid3 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid4 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid5 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid6 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;

}

}
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 481px) and (max-width: 767px) {

.eventsSpecialFluid{
    background-color: #fff;

}
.eventsSpecialWrapper{
    background-color: #fff;
    padding: 3rem 0rem;

}
.eventsSpecialHeader h5{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    padding: 16px 0px 0px 0px;
    color: #84449a;
}
.eventsSpecialHeader p{
    font-family: 'Oregano', cursive; 
    font-size: 1em;
    color: #84449a;
    margin-top: -4px;
}
.specialEvents{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:  180px 180px;
    column-gap: 1rem;
    row-gap: 2rem;
}
.eventGrid1{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/1_ndudyn");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.eventGrid2{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/2_qjasnl");
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    
}
.eventGrid3{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/3_qgpid8");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid4{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/4_uwfpbx");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid5{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/55_v48gjk");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid6{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/6_zsylk2");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid1 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid2 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid3 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid4 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid5 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid6 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;

}

   
  }
  
  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px) {

.eventsSpecialFluid{
    background-color: #fff;

}
.eventsSpecialWrapper{
    background-color: #fff;
    padding: 3rem 0rem;

}
.eventsSpecialHeader h5{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    padding: 16px 0px 0px 0px;
    color: #84449a;
}
.eventsSpecialHeader p{
    font-family: 'Oregano', cursive; 
    font-size: 1em;
    color: #84449a;
    margin-top: -4px;
}
.specialEvents{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:  180px 180px;
    column-gap: 1rem;
    row-gap: 2rem;
}
.eventGrid1{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/1_ndudyn");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.eventGrid2{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/2_qjasnl");
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    
}
.eventGrid3{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/3_qgpid8");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid4{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/4_uwfpbx");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid5{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/55_v48gjk");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid6{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/6_zsylk2");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid1 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid2 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid3 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid4 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid5 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid6 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;

}

   
  }
  
  /* Media Query for Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px) {

.eventsSpecialFluid{
    background-color: #fff;

}
.eventsSpecialWrapper{
    background-color: #fff;
    padding: 3rem 0rem;

}
.eventsSpecialHeader h5{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    padding: 16px 0px 0px 0px;
    color: #84449a;
}
.eventsSpecialHeader p{
    font-family: 'Oregano', cursive; 
    font-size: 1em;
    color: #84449a;
    margin-top: -4px;
}
.specialEvents{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:  180px 180px;
    column-gap: 1rem;
    row-gap: 2rem;
}
.eventGrid1{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/1_ndudyn");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.eventGrid2{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/2_qjasnl");
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    
}
.eventGrid3{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/3_qgpid8");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid4{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/4_uwfpbx");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid5{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/55_v48gjk");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid6{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/6_zsylk2");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid1 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid2 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid3 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid4 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid5 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid6 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;

}

   
  }
  
  /* Media Query for Large screens */
  @media (min-width: 1281px) {

.eventsSpecialFluid{
    background-color: #fff;

}
.eventsSpecialWrapper{
    background-color: #fff;
    padding: 3rem 0rem;

}
.eventsSpecialHeader h5{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    padding: 16px 0px 0px 0px;
    color: #84449a;
}
.eventsSpecialHeader p{
    font-family: 'Oregano', cursive; 
    font-size: 1em;
    color: #84449a;
    margin-top: -4px;
}
.specialEvents{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:  180px 180px;
    column-gap: 1rem;
    row-gap: 2rem;
}
.eventGrid1{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/1_ndudyn");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.eventGrid2{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/2_qjasnl");
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    
}
.eventGrid3{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/3_qgpid8");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid4{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/4_uwfpbx");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid5{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/55_v48gjk");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid6{
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/6_zsylk2");
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.eventGrid1 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid2 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
    
}
.eventGrid3 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid4 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid5 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;
}
.eventGrid6 h5{
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    background-color: #84449a;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: flex-start;
    padding: 3px 6px;

}

    
  }
  