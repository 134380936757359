.quickContactFluid
{
    background-color: #84449a;
}
.quickContactWrapper{
    padding: 3rem 0rem
}
.quickContactText{
    text-align: center;
    font-family: 'Lato', sans-serif;
}
.quickContactText h5{
    color: #fff;
    font-weight: bold;
}
.quickContactText p{
    color: #fff;
}
.quickContacts{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin: auto;
    border: 1px solid #e5e5e5;
    padding: 6px;
}
.quickContacts h5{
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    text-decoration: underline;
}
.quickContacts p{
    color: #fff;
    font-family: 'Lato', sans-serif;
}