/* .carousel-item:after {
    background: transparent url("http://i.imgur.com/8B7UFiv.png") repeat;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  } */
  .main-wrapper{
	position: relative;
  }

  .overlay {
	background: transparent url("http://i.imgur.com/8B7UFiv.png") repeat !important;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
}