.contactFormFluid{
    background-color: #FAF9F6;
    /* background-color: #84449a; */
    
}
.contactFormWrapper{
    position: relative;
    padding: 4em 0em;
    height: fit-content;
 
}
.contactFormBox{
    padding: 1em 0em;
    width: 80% !important;
    /* background-color: #faf9f9; */
    background-color: #84449a;


}
.contactFormContent{
    /* background-color: #faf9f9; */
    right: 5%;
    height: fit-content;
    position: relative;
}
.contactFormContentLeft{
    background-color: #000;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
}
.contactFormContentRight{
    background-color: #84449a;


}
.contactRow{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
}
.contactFormContentLeft h5{
    color: #fff;
    padding: 1em 0em;
}
.contactFormContentRight h5{
    color: #fff;
    padding: 1em 0em;
}
.contactInfo{
    display: flex;
    flex: 1;
    font-family: 'Lato', sans-serif;
    color: #fff;
    flex-direction: column;
    justify-content: space-around;
}
.socialLinks{
    padding: 1em 0em;
    align-self: center;

}