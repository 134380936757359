.reservationHeroFluid{
    background-image: url("../../../../assets/images/interior.jpg");
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-bottom-left-radius: 1.3em;
    border-bottom-right-radius: 1.3em;
    margin-bottom: 5rem;
}
.reservationHeroWrapper{
    padding: 16em 0em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reservationBox{
    background-image: url("../../../../assets/images/purple.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: max-content;
    padding: 1rem 0rem;
    top: 5rem;
    height: fit-content;
    border-radius: 8px;
    bottom: -2rem;
}
.formWrapper{
    padding: 4px 6px;
    margin: 0rem 1rem;
    width: auto;
}
.formWrapper h5{
    padding: 4px 6px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-family: 'Lato', sans-serif;

}
.formWrapper p{
    text-align: center;
    color: #fff;
    font-family: 'Lato', sans-serif;
}
.reserveFormLabel{
    color: #fff;
    font-family: 'Lato', sans-serif;

}
.reserveForm{
    border-top: 1px solid #fff;
    width: fit-content;
}
.reserveButton{
    background-color: #fff !important;
    color: #84449a !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    border: none !important;

}
