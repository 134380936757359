.eventsHeroFluid{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url("https://res.cloudinary.com/dv6plapfo/image/upload/f_auto,q_auto/v1/webassets/catering_wbbxqz");
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;


}

.eventsHeroText{
    padding: 5rem 0rem;
}
.eventsHeroText h5{
    font-size: 2em;
    font-family: 'Roboto', sans-serif;
    color: #FFF;
}
.eventsHeroText p{
    color: #fff;
    font-size: 1em;
    width: 60%;
    color: #FFF;
    font-family: 'Lato', sans-serif;
}
.callBackForm{
    padding-top: 1rem;
}
.callbackButton{
    background-color: #84449a !important;
    border: 1px solid #84449a !important;
    font-family: 'Lato', sans-serif;
    font-weight: bold;

}